

export async function getAllUsersAPI(user) {
    try {
        const response = await fetch(`/api/users/get_all`, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${user.access_token}`
              },
        });
        const users = await response.json();
        return users;
    } catch (err) {
        console.log(err);
    }
}

// export function getAllUsersAPI() {
//     fetch(`/api/users/get_all`).then(response => {
//         response.json().then(users => {
//             if (users){
//                 return users
//             }
//         }, err => {
//             console?log()
//         })
//     }, err => {
//         console
//     });
// }

export async function loginUserAPI(username, password) {
    const response = await fetch(`/api/token`,{
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json'
        },
        body: `grant_type=password&username=${username}&password=${password}&scope=&client_id=&client_secret=`,
        // body: JSON.stringify({username, password})
    } );
    const user = await response.json();
    const status = response.status;
    return user;
}

export async function loginUserGoogleAPI(token) {
    const response = await fetch(`/api/oauth?token=${token}`,{
        method: 'POST', // or 'PUT'
    } );
    const user = await response.json();
    const status = response.status;
    return user;
}

export async function registerUserAPI(userObject) {
    const response = await fetch(`/api/users/create`,{
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
            username: userObject.username,
            firstname: userObject.firstname,
            lastname: userObject.lastname,
            email: userObject.email,
            gender: userObject.gender,
            password: userObject.password,
        }),
    });
    const user = await response.json();
    const status = response.status;
    return user;
}

export async function registerInactiveUserAPI(user, userObject) {
    const response = await fetch(`/api/users/createInactive`,{
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${user.access_token}`

        },
        body: JSON.stringify({
            firstname: userObject.firstname,
            lastname: userObject.lastname,
            gender: userObject.gender,
        }),
    });
    const res = await response.json();
    const status = response.status;
    return res;
}

export async function forgotPasswordChangeAPI(email, password, token) {
    const response = await fetch(`/api/forgotPassword/change`,{
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            email,
            password,
        }),
    });
    const user = await response.json();
    const status = response.status;
    return user;
}

export async function forgotPasswordSendTokenAPI(email) {
    const response = await fetch(`/api/forgotPassword/sendToken?email=${email}`,{
        method: 'POST', // or 'PUT'
        headers: {
          'accept': 'application/json'
        },
    });
    const res = await response.json();
    const status = response.status;
    return res;
}

export async function editUserAPI(new_user, token) {
    const response = await fetch(`/api/users/edit`,{
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(new_user),
    });
    const user = await response.json();
    const status = response.status;
    return user;
}

export async function editUserPasswordAPI(currentPassword, password, token) {
    const response = await fetch(`/api/users/changePassword`,{
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            currentPassword,
            password
        }),
    });
    const user = await response.json();
    const status = response.status;
    return user;
}